@font-face {
    font-family: 'Rosatom Light';
    src: url('../fonts/Rosatom Light.ttf') format('truetype');
    font-weight: 350;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Rosatom';
    src: url('../fonts/Rosatom Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}