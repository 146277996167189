@import '_fonts';

* {
    margin: 0;
    padding: 0;
    border: none;
    list-style: none;
    box-sizing: border-box;

    color: #20386A;
    font-family: 'Rosatom Light';
    font-weight: 350;
    font-style: normal;
    line-height: normal;

    &::before,
    &::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    &:focus,
    &:active {
        outline: none;
    }
}

a,
a:link,
a:visited,
a:hover {
    text-decoration: none;
}

ul,
ul li {
    list-style: none;
}

button,
input {
    box-shadow: none;
    background: transparent;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input::-ms-clear {
    display: none;
}

label {
    cursor: pointer;
}

img {
    vertical-align: top;
}

// Стили

header {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 10px 30px;
    border-bottom: 1px solid #414042;
    display: flex;
    justify-content: center;
}

header svg {
    width: 120px;
}

@media (min-width: 1366px) {
    header svg {
        width: 150px;
    }
}

.main {
    width: 100%;
    min-height: calc(100vh - 76px);
    position: relative;
    padding-bottom: 45px;
}

@media (min-width: 1024px) {
    .main {
        max-width: 1024px;
        min-height: calc(100vh - 90px);
        margin: 0 auto;
    }
}

.main::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: #6EAADD;
}

.intro {
    position: relative;
}

img {
    width: 100%;
    height: auto;
}

.info-block {
    width: 100%;
    padding: 20px 30px 30px;

    display: flex;
    flex-direction: column;
    gap: 15px;
}

h1 {
    font-family: 'Rosatom';
    font-weight: 700;
    font-size: 26px;
}

@media (min-width: 768px) {
    h1 {
        font-size: 32px;
    }
}

@media (min-width: 1366px) {
    h1 {
        font-size: 36px;
    }
}

.pick-out,
h1 {
    position: relative;
    padding-left: 15px;
}

@media (min-width: 768px) {

    .pick-out,
    h1 {
        padding-left: 20px;
    }
}

@media (min-width: 1366px) {

    .pick-out,
    h1 {
        padding-left: 25px;
    }
}

.pick-out::before,
h1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #6EAADD;
}

@media (min-width: 768px) {

    .pick-out::before,
    h1::before {
        width: 7px;
    }
}

@media (min-width: 1366px) {

    .pick-out::before,
    h1::before {
        width: 10px;
    }
}

h2,
b {
    font-family: 'Rosatom';
    font-weight: 700;
}

h2,
p,
b {
    font-size: 18px;
    line-height: 20px;
}

@media (min-width: 768px) {

    h2,
    p,
    b {
        font-size: 22px;
        line-height: 24px;
    }
}

@media (min-width: 1366px) {

    h2,
    p,
    b {
        font-size: 24px;
        line-height: 26px;
    }
}

.img-in-text {
    position: relative;
}

.img-in-text::after {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #6EAADD;
    transform: translateY(50%);
}

.img-in-text svg {
    position: absolute;
    bottom: 3px;
    left: 25px;
    width: 30px;
    height: 30px;
    z-index: 1;
    transform: translateY(50%);
}